import React, { useState, useEffect } from "react";

export const DateTime = () => {

    var [date, setDate] = useState(new Date());

    useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer);
        };
    });

    return (
        <div className="text-center">
            <p style={{fontSize:"3.25vw"}} className="leading-none">
                {date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                })}
            </p>
            <p className="opacity-50 uppercase text-lg tracking-widest leading-none">
                <strong>
                    {date.toLocaleDateString([], {
                        year: 'numeric', 
                        month: 'long', 
                        day: 'numeric',
                    })}
                </strong>
            </p>
        </div>
    );
};

export default DateTime;
