// Scheduled and prediction will overlap.
// need to cut n number of predicted stops from scheduled

//reorder all trains by arrival before setting to state

import React, { Component } from "react";
import { iso_to_seconds } from './../Utils'
import MBTALogo from "../assets/images/mbta-logo.png";
import TLogo from "../assets/images/mbta-t.png";
import TrainImage from "../assets/images/train.jpg";

const API_KEY = "7b9199fcfb024d1e86a0bee07183de49";
const STATION_STOP = "place-WML-0035"; // (BOSTON LANDING)

let date = new Date()
let ISO_date = date.toISOString()
let current_time_in_seconds = iso_to_seconds(ISO_date)
let k = 0

class Train extends Component {
    state = {
        trains: undefined,
        inbound: undefined,
        outbound: undefined,
        mbta_is_loaded: false,
    }

    getTimeDifference = (arrivalTime) => {
        return parseInt(iso_to_seconds(arrivalTime) - current_time_in_seconds)
    }

    current_time_in_seconds = (arrivalTime) => {
        return parseInt(iso_to_seconds(arrivalTime) - current_time_in_seconds)
    }

    displayBusTime(arrivalTime, i) {
        // some buses return arrivalTime as null, we need to reset value i to k to reset ech loop at 0
        if (k > i) {
            console.log('reset')
            k = 0
        }
        let difference = parseInt(
            iso_to_seconds(arrivalTime) - current_time_in_seconds
        )

        let comma = ''
        let displayTime = ''

        if (k + 1 < 2) {
            comma = ','
        }
        if (k + 1 > 2) {
            displayTime = false
        } else if (difference < 0) {
            displayTime = (
                <span style={{ fontSize: '1.35vw' }}>
                    BRD
                    <span style={{ fontSize: '1vw' }} className="font-normal">
                        {comma}
                    </span>
                </span>
            )
        } else if (difference < 90) {
            displayTime = (
                <span style={{ fontSize: '1.35vw' }}>
                    ARR
                    <span style={{ fontSize: '1vw' }} className="font-normal">
                        {comma}
                    </span>
                </span>
            )
        } else {
            displayTime = (
                <span style={{ fontSize: '1.4vw' }}>
                    {parseInt(difference / 60)}
                    <span style={{ fontSize: '1vw' }} className="font-normal">
                        {' '}
                        Min{comma}
                    </span>
                </span>
            )
        }
        k++
        if (displayTime !== false) {
            return (
                <p
                    className="text-right font-bold"
                    style={{ marginLeft: '1vw' }}
                    key={i}
                >
                    {displayTime}
                </p>
            )
        } else {
            return
        }
    }

    componentDidMount() {
        this.getTrains()
    }

    getTrains = async (e) => {
        const api_call = await fetch(
            `https://api-v3.mbta.com/predictions?stop=${STATION_STOP}&api_key=${API_KEY}`
        )
        const api_call2 = await fetch(
            `https://api-v3.mbta.com/schedules?stop=${STATION_STOP}&api_key=${API_KEY}`
        )
        const predictiondata = await api_call.json()
        const scheduledata = await api_call2.json()
        if (predictiondata && scheduledata) {
            let currentTrains = []
            let inboundTrains = undefined
            let outboundTrains = undefined
            // Hiding predictions because it will overlap with scheduled
            // ---
            // predictiondata.data.forEach((element) => {
            //     currentTrains.push(element)
            // })
            // predictiondata.data.map((train) =>
            //     train.attributes && train.attributes.direction_id === 0
            //         ? (outboundTrains = true)
            //         : (inboundTrains = true)
            // )
            scheduledata.data.map(
                (train) =>
                    train.attributes &&
                    this.getTimeDifference(train.attributes.arrival_time) > 0 &&
                    currentTrains.push(train)
                // Trying to remove the overlapped train from prediction and scheduled
                // ---
                // currentTrains && currentTrains.forEach(element => {
                //     // if withing 5 minutes of a predicted train remove from schedule - use console log as starting point
                //     if ( true ) {
                //         console.log(
                //             (Math.round(this.current_time_in_seconds(train.attributes.arrival_time) / 60)) - (Math.round(this.current_time_in_seconds(element.attributes.arrival_time) / 60))
                //         )
                //     }
                // })
            )
            currentTrains.sort(function (a, b) {
                return a.attributes.arrival_time < b.attributes.arrival_time
                    ? -1
                    : a.attributes.arrival_time > b.attributes.arrival_time
                    ? 1
                    : 0
            })
            currentTrains.map((train) =>
                train.attributes && train.attributes.direction_id === 0
                    ? (outboundTrains = true)
                    : (inboundTrains = true)
            )
            this.setState({
                trains: currentTrains,
                inbound: inboundTrains,
                outbound: outboundTrains,
                mbta_is_loaded: true,
                error: '',
            })
        } else {
            this.setState({
                trains: undefined,
                inbound: undefined,
                outbound: undefined,
                mbta_is_loaded: false,
                error: 'Please enter a city and state and try again.',
            })
        }
    }

    render() {
        if (!this.state.mbta_is_loaded) {
            return <h1>trains</h1>
        } else {
            return (
                <div className="w-full h-full border-2 border-light-gray rounded-xl block p-4 pt-0 Train">
                    <div className="flex absolute right-0 items-end justify-end p-4 rounded-tr-lg header-gradient">
                        <div className="w-3/5">
                            <img
                                alt="MBTA Logo"
                                style={{ maxHeight: '6.5vh' }}
                                src={MBTALogo}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-2/5">
                            <img alt="MBTA bus" src={TrainImage} />
                        </div>
                    </div>
                    <h2
                        style={{ fontSize: '1.375vw' }}
                        className="font-bold leading-none"
                    >
                        Commuter Rail Schedule
                    </h2>
                    <div className="mb-4 mt-0.5 bg-black w-full h-0.5"></div>
                    {/* Bus 1 */}

                    <div className="mb-4">
                        <div className="flex items-center py-2">
                            <img
                                className="font-bold pr-4"
                                src={TLogo}
                                alt="mbta logo"
                                style={{
                                    width: '3.5vw',
                                }}
                            />
                            <div>
                                <p
                                    className="font-bold m-0"
                                    style={{
                                        fontSize: '1.25vw',
                                        lineHeight: '0.75',
                                    }}
                                >
                                    Boston Landing
                                </p>
                                <p
                                    className="font-bold"
                                    style={{
                                        fontSize: '1vw',
                                        letterSpacing: '0.125vw',
                                        color: 'purple',
                                    }}
                                >
                                    100 ARTHUR STREET
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* INBOUND */}
                    {this.state.inbound && (
                        <div className="flex">
                            <p className="font-bold">Inbound</p>
                            <div className="flex ml-auto">
                                {this.state.trains.length !== 0 &&
                                    this.state.trains &&
                                    this.state.trains.map(
                                        (train, i) =>
                                            train.attributes.direction_id ===
                                                1 &&
                                            this.displayBusTime(
                                                train.attributes.arrival_time,
                                                i
                                            )
                                    )}
                            </div>
                        </div>
                    )}
                    {/* OUTBOUND */}
                    {this.state.outbound !== undefined && (
                        <div className="flex">
                            <p className="font-bold">Outbound</p>
                            <div className="flex ml-auto">
                                {this.state.trains.length !== 0 &&
                                    this.state.trains &&
                                    this.state.trains.map(
                                        (train, i) =>
                                            train.attributes.direction_id ===
                                                0 &&
                                            this.displayBusTime(
                                                train.attributes.arrival_time,
                                                i
                                            )
                                    )}
                            </div>
                        </div>
                    )}
                </div>
            )
        }
    }
}

export default Train;