import React, { Component } from "react";
import overcast from "../../assets/images/weather/overcast.png";
import sunny from "../../assets/images/weather/ic sun.png";
import weatherIcon8 from "../../assets/images/weather/ic weather 8.png";
import weatherIcon22 from "../../assets/images/weather/ic weather 22.png";
import weatherIcon43 from "../../assets/images/weather/ic weather 43.png";
import fog from "../../assets/images/weather/ic sky.png";
import Wind from "../../assets/images/weather/wind.png";
import Humidity from "../../assets/images/weather/humidity.png";

// weatherbit api
const API_KEY = "bfc6597cc12b4529a44ac49a4df334ac";
const city = "brighton";
const state = "ma";

const updateWeatherIcon = (iconCode) => {
    // iconCode = 700;
    if (
      iconCode === 801 ||
      iconCode === 802 ||
      iconCode === 803 ||
      iconCode === 804
    ) {
      // Cloudy
      return overcast;
    } else if (iconCode === 800) {
      // Sunny
      return sunny;
    } else if (
      iconCode === 200 ||
      iconCode === 201 ||
      iconCode === 202 ||
      iconCode === 230 ||
      iconCode === 231 ||
      iconCode === 232 ||
      iconCode === 233
    ) {
      // Thunderstorms
      return weatherIcon22;
    } else if (
      iconCode === 300 ||
      iconCode === 301 ||
      iconCode === 302 ||
      iconCode === 500 ||
      iconCode === 501 ||
      iconCode === 502 ||
      iconCode === 511 ||
      iconCode === 520 ||
      iconCode === 521 ||
      iconCode === 522
    ) {
      // Rain
      return weatherIcon8;
    } else if (
      iconCode === 600 ||
      iconCode === 601 ||
      iconCode === 602 ||
      iconCode === 610 ||
      iconCode === 611 ||
      iconCode === 612 ||
      iconCode === 621 ||
      iconCode === 622 ||
      iconCode === 623 
    ) {
      // Snow
      return weatherIcon43;
    } else if (
      iconCode === 700 ||
      iconCode === 711 ||
      iconCode === 721 ||
      iconCode === 731 ||
      iconCode === 741 ||
      iconCode === 751
    ) {
      return fog;
    }
};

class WeatherApp extends Component {
    state = {
        weather: undefined,
        error: undefined,
    };

    componentDidMount() {
        this.getWeather();
    }

    getWeather = async (e) => {
        const api_call = await fetch(
            `https://api.weatherbit.io/v2.0/current?city=${city},${state}&units=I&key=${API_KEY}`
        );
        const data = await api_call.json();

        if (data) {
            // console.log(data);
            this.setState({
                weather: data.data[0],
                error: "",
            });
        } else {
            this.setState({
                weather: undefined,
                error: "Please enter a city and state and try again.",
            });
        }
    };

    render() {
        // // RELOADS EVEERY 30 SEC
        // setInterval(function () {
        //     window.location.reload();
        // }, 30000);
        if (!this.state.weather) {
            return <h1>Weather</h1>;
        } else {
            // console.log(this.state)
            return (
                <div>
                    <div className="flex items-center mb-4">
                        <img
                            style={{
                                width: '7.5vw',
                                filter: 'brightness(0%)',
                            }}
                            className="opacity-75 mr-2"
                            src={updateWeatherIcon(
                                this.state.weather.weather.code
                            )}
                            alt={this.state.weather.weather.description}
                        />
                        <div className="">
                            <p
                                style={{ fontSize: '3.25vw' }}
                                className="leading-none text-center"
                            >
                                {this.state.weather.temp}&deg;F
                            </p>
                            <p className="text-center capitalize" style={{ fontSize: '1.15vw' }}>
                                <strong>
                                    {this.state.weather.weather.description}
                                </strong>
                            </p>
                        </div>
                    </div>
                    <p
                        style={{
                            marginTop: '-1.5vh',
                            fontSize: '1.35vw',
                            margin: '0 -1vw',
                        }}
                        className="flex justify-between opacity-50"
                    >
                        <span className="flex items-center">
                            <img className="w-8 mr-3" src={Wind} alt="wind speed"/>
                            <strong>{this.state.weather.wind_spd}mph</strong>
                        </span>
                        <span className="flex items-center">
                            <img className="w-8 mr-2" src={Humidity} alt="humidity"/>
                            <strong>{this.state.weather.rh}%</strong>
                        </span>
                    </p>
                </div>
            )
        }
    }
}
export default WeatherApp;