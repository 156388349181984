import React, { Component } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

class ImageSlider extends Component {

    render() {
        return (
            <Splide
                options={{
                    type: 'fade',
                    autoplay: true,
                    rewind: true,
                    cover: true,
                    speed: 2000,
                    interval: 6000,
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    arrows: false,
                }}
                className="w-full h-full"
            >
                {this.props.images.map((image, i) => (
                    <SplideSlide
                        key={image.image.id * i}
                        className="w-full h-full"
                    >
                        <img src={image.image.url} alt="boston scenery 0" />
                    </SplideSlide>
                ))}
            </Splide>
        )
    }
}

export default ImageSlider;