import React, { Component } from 'react';
// import axios from 'axios';
import LyftLogo from "../assets/images/lyft-logo.svg";
import LyftImage from "../assets/images/lyft-image.jpg";
// import Lyft from "lyft-node";

// var clientId = "pat";
// var clientSecret = "cj5YqMl71MAS";


// const lyft = new Lyft(clientId, clientSecret);

// const query = {
//   start: {
//     latitude: 1,
//     longitude: 2,
//   },
// };

// lyft
//   .getRideTypes(query)
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((error) => {
//     console.log(error);
//   });

// axios.request({
//   url: "https://api.lyft.com/oauth/token",
//   type: "POST",
//   data: {
//     grant_type: "client_credentials",
//     scope: "public",
//   },
//   beforeSend: function (xhr) {
//     xhr.setRequestHeader(
//       "Authorization",
//       "Basic " + btoa(clientId + ":" + clientSecret)
//     );
//   },
//   success: function (response) {
//     console.log("success");
//     console.log(response);
//   },
//   error: function (error) {
//     console.log("fail");
//     console.log(error);
//   },
// });



// axios
//   .request({
//     url: "https://api.lyft.com/oauth/token",
//     method: "post",
//     baseURL: "https://api.lyft.com/",
//     auth: {
//       username: "pat",
//       password: "cj5YqMl71MAS",
//     },
//     data: {
//       grant_type: "client_credentials",
//       scope: "public",
//     },
//   })
//   .then(function (res) {
//     console.log("lyft res");
//     console.log(res);
//   });


function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

class LyftView extends Component {
    state = {
        trains: undefined,
        buses: undefined,
        mbta_is_loaded: false,
    };


    render() {
        // if (!this.state.trains) {
        //     return (
        //         <h1>Lyft</h1>
        //     )
        // } else {
            return (
                <div className="w-full h-full border-2 border-light-gray rounded-xl block p-4 pt-0 Train">
                    <div className="flex absolute right-0 items-end justify-end p-4 rounded-tr-lg header-gradient">
                        <div className="w-3/5">
                            <img
                                style={{ maxHeight: '6.5vh' }}
                                src={LyftLogo}
                                alt="lyft-menu"
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-2/5">
                            <img src={LyftImage} alt="lyft-menu" />
                        </div>
                    </div>
                    <h2
                        style={{ fontSize: '1.375vw', marginTop: '1vh' }}
                        className="font-bold leading-none"
                    >
                        Lyft
                    </h2>
                    <div className="mt-0.5 bg-black w-full h-0.5"></div>
                    <div style={{ marginTop: '3.5vh' }}>
                        <div
                            style={{ fontSize: '1vw' }}
                            className="mt-4 flex relative"
                        >
                            <p
                                style={{ fontSize: '1.25vw' }}
                                className="absolute"
                            >
                                <strong>Lyft</strong>
                            </p>
                            <ul
                                style={{ fontSize: '1vw' }}
                                className="ml-auto text-right flex flex-col w-full"
                            >
                                <li
                                    style={{ fontSize: '0.8vw' }}
                                    className="border-b w-full pb-2 mb-0.5 opacity-50"
                                >
                                    1x rate
                                </li>
                                <li className="border-b border-light-gray w-full pb-0.5 mb-0.5 font-bold">
                                    <span style={{ fontSize: '1.4vw' }}>
                                        {getRandomInt(3, 8)}
                                        <span
                                            style={{ fontSize: '1vw' }}
                                            className="font-normal"
                                        >
                                            {' '}
                                            Min
                                        </span>
                                    </span>
                                </li>
                                <li className="w-full pb-0.5 mb-0.5 font-bold">
                                    <span style={{ fontSize: '1.4vw' }}>
                                        {getRandomInt(6, 18)}
                                        <span
                                            style={{ fontSize: '1vw' }}
                                            className="font-normal"
                                        >
                                            {' '}
                                            Min
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style={{ marginTop: '2.5vh' }}>
                        <div
                            style={{ fontSize: '1vw' }}
                            className="mt-4 flex relative"
                        >
                            <p
                                style={{ fontSize: '1.25vw' }}
                                className="absolute"
                            >
                                <strong>Lyft XL</strong>
                            </p>
                            <ul
                                style={{ fontSize: '1vw' }}
                                className="ml-auto text-right flex flex-col w-full"
                            >
                                <li
                                    style={{ fontSize: '0.8vw' }}
                                    className="border-b w-full pb-2 mb-0.5 opacity-50"
                                >
                                    1.25x rate
                                </li>
                                <li className="border-b border-light-gray w-full pb-0.5 mb-0.5 font-bold">
                                    <span style={{ fontSize: '1.4vw' }}>
                                        {getRandomInt(3, 12)}
                                        <span
                                            style={{ fontSize: '1vw' }}
                                            className="font-normal"
                                        >
                                            {' '}
                                            Min
                                        </span>
                                    </span>
                                </li>
                                <li className="w-full pb-0.5 mb-0.5 font-bold">
                                    <span style={{ fontSize: '1.4vw' }}>
                                        {getRandomInt(3, 12)}
                                        <span
                                            style={{ fontSize: '1vw' }}
                                            className="font-normal"
                                        >
                                            {' '}
                                            Min
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style={{ marginTop: '2.5vh' }}>
                        <div
                            style={{ fontSize: '1vw' }}
                            className="mt-4 flex relative"
                        >
                            <p
                                style={{ fontSize: '1.25vw' }}
                                className="absolute"
                            >
                                <strong>Lyft Lux</strong>
                            </p>
                            <ul
                                style={{ fontSize: '1vw' }}
                                className="ml-auto text-right flex flex-col w-full"
                            >
                                <li
                                    style={{ fontSize: '0.8vw' }}
                                    className="border-b w-full pb-2 mb-0.5 opacity-50"
                                >
                                    1.5x rate
                                </li>
                                <li className="border-b border-light-gray w-full pb-0.5 mb-0.5 font-bold">
                                    <span style={{ fontSize: '1.4vw' }}>
                                        {getRandomInt(4, 22)}
                                        <span
                                            style={{ fontSize: '1vw' }}
                                            className="font-normal"
                                        >
                                            {' '}
                                            Min
                                        </span>
                                    </span>
                                </li>
                                <li className="w-full pb-0.5 mb-0.5 font-bold">
                                    <span style={{ fontSize: '1.4vw' }}>
                                        {getRandomInt(10, 22)}
                                        <span
                                            style={{ fontSize: '1vw' }}
                                            className="font-normal"
                                        >
                                            {' '}
                                            Min
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        // }
    }
}

export default LyftView;