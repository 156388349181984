import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import starbucks from '../../assets/images/starbucks.jpg'
import homedepot from '../../assets/images/homedepot.jpg'
import BuildingImage1 from '../../assets/images/building-image-1.jpg'

let dateSplit
let month
let day

class Info extends Component {

    renderSlide(element) {
        if (element.date) {
            this.dateSplit = element.date.split(' ');
            this.month = this.dateSplit[0]
            this.day = this.dateSplit[1]
            this.day = this.day.substring(0, this.day.length - 1)
        } else {
            this.day = null;
            this.month = null;
        }
        // let day = element.date
        return (
            <SplideSlide key={element.image.id} className="w-full h-full">
                {element.acf_fc_layout === 'advertisement' ? (
                    <img src={element.image.url} alt="ad" />
                ) : (
                    <div>
                        <h2
                            style={{ fontSize: '1.25vw' }}
                            className="leading-none"
                        >
                            Information
                        </h2>
                        <div className="mt-0.5 bg-black w-full h-0.5"></div>
                        <p
                            style={{ fontSize: '1vw' }}
                            className="mt-2 leading-snug uppercase"
                        >
                            <strong>{element.title}</strong>
                        </p>
                        <p
                            style={{ fontSize: '1vw' }}
                            className="mb-2 leading-snug flex opacity-50 items-end"
                        >
                            {element.location}
                            <span
                                className="ml-auto"
                                style={{ fontSize: '0.8vw' }}
                            >
                                0.3 miles
                            </span>
                        </p>
                        <div className="mt-2 flex">
                            <div className="w-2/5 mr-4">
                                <div
                                    style={{
                                        height: '11vh',
                                        backgroundImage: `url(${element.image.url})`,
                                    }}
                                    className="w-full h-20 bg-cover bg-center"
                                ></div>
                                <p
                                    style={{ fontSize: '1vw' }}
                                    className="font-bold text-center uppercase"
                                >
                                    {this.month}
                                </p>
                                <p
                                    style={{ fontSize: '3vw' }}
                                    className="text-center font-light leading-none"
                                >
                                    {this.day}
                                </p>
                            </div>
                            <div className="w-3/5">
                                <p
                                    style={{ fontSize: '1vw' }}
                                    className="leading-snug"
                                >
                                    <strong>7:00PM - 10:00PM</strong>
                                </p>
                                <p
                                    style={{ fontSize: '1vw' }}
                                    className="leading-snug"
                                >
                                    {element.description}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </SplideSlide>
        )
    }

    render() {
        return (
            <div className="relative h-full">
                <Splide
                    options={{
                        type: 'loop',
                        autoplay: true,
                        rewind: true,
                        cover: true,
                        speed: 2000,
                        interval: 10000,
                        pauseOnHover: false,
                        pauseOnFocus: false,
                        arrows: false,
                    }}
                    className="w-full h-full"
                >
                    {this.props.infoSlides.map((element) => (
                        this.renderSlide(element)                        
                    ))}
                </Splide>
            </div>
        )
        
    }
}

export default Info;