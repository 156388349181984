import React, { Component } from 'react';
import { prepend_zero_to_single_digits } from './Utils';
import Train from "./components/Train";
import Information from './components/bluebikes/info-working'
import Uber from './components/Uber'
import Lyft from './components/lyft'
import BlueBikes from './components/bluebikes/bluebikes'
import "tailwindcss/tailwind.css";
import "./assets/css/tailwind.css";
import Weather from './components/weather/weather'
import DateTime from './components/weather/DateTime'
import Events from './components/events'
import MBTA from './components/mbta'
// import MBTA from './components/mbta-new'
import ImageSlider from './components/ImageSlider' 
import './App.css';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import "react-awesome-slider/dist/styles.css";

const publicTransport = (
    <Splide
        options={{
            type: "loop",
            autoplay: true,
            rewind: true,
            cover: true,
            speed: 2000,
            interval: 10000,
            pauseOnHover: false,
            pauseOnFocus: false,
            arrows: false,
        }}
        className="w-full h-full"
    >
        <SplideSlide className="w-full h-full">
            <MBTA />
        </SplideSlide>
        <SplideSlide className="w-full h-full">
            <Train />
        </SplideSlide>
    </Splide>
);

const privateTransport = (
    <Splide
        options={{
            type: 'loop',
            autoplay: true,
            rewind: true,
            cover: true,
            speed: 2000,
            interval: 10000,
            pauseOnHover: false,
            pauseOnFocus: false,
            arrows: false,
        }}
        className="w-full h-full"
    >
        <SplideSlide className="w-full h-full">
            <BlueBikes />
        </SplideSlide>
        <SplideSlide className="w-full h-full">
            <Lyft />
        </SplideSlide>
        <SplideSlide className="w-full h-full">
            <Uber />
        </SplideSlide>
    </Splide>
)

const logo = 'https://pat-cooney.com/app/uploads/2021/09/logo.png'
// Get Day information
const months = ["January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
];
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
const date = new Date();
const year = date.getFullYear();
//month is 0 indexed
let hour = prepend_zero_to_single_digits(date.getHours());
let minute = prepend_zero_to_single_digits(date.getMinutes());
let month = prepend_zero_to_single_digits(date.getMonth() + 1);
let month_name = months[date.getMonth()];
let day = prepend_zero_to_single_digits(date.getDate());
let day_name = days[date.getDay()];
//week ahead
let weekAhead = new Date(date.getFullYear(), date.getMonth(), date.getDate()+7);
let weekAhead_year = weekAhead.getFullYear();
let weekAhead_month = prepend_zero_to_single_digits(weekAhead.getMonth() + 1);
let weekAhead_day = prepend_zero_to_single_digits(weekAhead.getDate());
weekAhead = `${weekAhead_year}-${weekAhead_month}-${weekAhead_day}`


class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            token: 'wp-token',
            count: 0,
            images: null,
            year,
            month,
            month_name,
            day,
            day_name,
            hour,
            minute,
            weekAhead,
        }
    }

    componentDidMount() {
        fetch(`https://transit1550.launchpaddev.com/wp-json/acf/v3/pages/8`)
            .then(function (response) {
                return response.json()
            }
		).then((value) => {
            this.setState({
                images: value.acf.images,
				message: value.acf.top_banner,
				infoSlides: value.acf.slide,
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error,
                })
            })
        })
    }

    render() {
        const { error, isLoaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <div className="App max-w-screen-lg">
                    <h2 className="capitalize text-2xl mb-8 font-gotham-bold">
                        loading
                    </h2>
                </div>
            );
        } else {
          	return (
                <div className="h-screen flex flex-col overflow-hidden">
                    <div
                        style={{ height: '7vh' }}
                        className="flex items-center bg-blue text-lg font-bold whitespace-nowrap"
                    >
                        <p style={{ fontSize: '1.25vw' }} className="ticker">
                            {this.state.message}
                        </p>
                    </div>

                    <div
                        style={{ height: '59vh' }}
                        className="grid grid-cols-4 w-full"
                    >
                        <div className="public-transport m-2 relative overflow-hidden">
                            {publicTransport}
                        </div>

                        <div className="col-span-2">
                            <ImageSlider images={this.state.images} />
                        </div>
                        <div className="private-transport m-2 relative overflow-hidden">
                            {privateTransport}
                        </div>
                    </div>
                    <div
                        style={{ height: '34vh' }}
                        className="grid grid-cols-4 w-full"
                    >
                        <div
                            className="bg-cover bg-center"
                            style={{ backgroundImage: `url(${logo})` }}
                        ></div>
                        <div className="p-2">
                            <Information infoSlides={this.state.infoSlides} />
                        </div>
                        <div className="p-2">
                            <Events
                                year={this.state.year}
                                month={this.state.month}
                                day={this.state.day}
                                weekAhead={this.state.weekAhead}
                                months={months}
                            />
                        </div>
                        <div className="bg-blue p-4 flex flex-col justify-around items-center">
                            <DateTime />
                            <Weather />
                        </div>
                    </div>
                </div>
            )
        }
      }
    }

export default App;





