import React, { Component } from 'react';
import UberLogo from "../assets/images/uber-logo.png";
import UberImage from "../assets/images/uber-image.jpg";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

class Uber extends Component {
    render() {
        return (
          <div className="w-full h-full border-2 border-light-gray rounded-xl block p-4 pt-0 Train">
            <div className="flex absolute right-0 items-end justify-end p-4 rounded-tr-lg header-gradient">
              <div className="w-3/5">
                <img
                  style={{ maxHeight: "6.5vh" }}
                  src={UberLogo}
                  alt="uber-menu"
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-2/5">
                <img src={UberImage} alt="uber-menu" />
              </div>
            </div>
            <h2
              style={{ fontSize: "1.375vw", marginTop: "1vh" }}
              className="font-bold leading-none"
            >
              Uber
            </h2>
            <div className="mt-0.5 bg-black w-full h-0.5"></div>
            <div style={{ marginTop: "3.5vh" }}>
              <div style={{ fontSize: "1vw" }} className="mt-4 flex relative">
                <p style={{ fontSize: '1.25vw' }} className="absolute">
                  <strong>Uber</strong>
                </p>
                <ul
                  style={{ fontSize: "1vw" }}
                  className="ml-auto text-right flex flex-col w-full"
                >
                  <li
                    style={{ fontSize: "0.8vw" }}
                    className="border-b w-full pb-2 mb-0.5 opacity-50"
                  >
                    1x rate
                  </li>
                  <li className="border-b border-light-gray w-full pb-0.5 mb-0.5 font-bold">
                    {/* <span style={{ fontSize: '1.4vw' }}>
                        {getRandomInt(3, 8)}
                        <span
                            style={{ fontSize: '1vw' }}
                            className="font-normal"
                        >
                            {' '}
                            Min
                        </span>
                    </span> */}
                  </li>
                  <li className="w-full pb-0.5 mb-0.5 font-bold">
                    {/* <span style={{ fontSize: '1.4vw' }}>
                        {getRandomInt(6, 18)}
                        <span
                            style={{ fontSize: '1vw' }}
                            className="font-normal"
                        >
                            {' '}
                            Min
                        </span>
                    </span> */}
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ marginTop: "2.5vh" }}>
              <div style={{ fontSize: "1vw" }} className="mt-4 flex relative">
                <p style={{ fontSize: '1.25vw' }} className="absolute">
                  <strong>Uber XL</strong>
                </p>
                <ul
                  style={{ fontSize: "1vw" }}
                  className="ml-auto text-right flex flex-col w-full"
                >
                  <li
                    style={{ fontSize: "0.8vw" }}
                    className="border-b w-full pb-2 mb-0.5 opacity-50"
                  >
                    1.25x rate
                  </li>
                  <li className="border-b border-light-gray w-full pb-0.5 mb-0.5 font-bold">
                    {/* <span style={{ fontSize: '1.4vw' }}>
                        {getRandomInt(3, 12)}
                        <span
                            style={{ fontSize: '1vw' }}
                            className="font-normal"
                        >
                            {' '}
                            Min
                        </span>
                    </span> */}
                  </li>
                  <li className="w-full pb-0.5 mb-0.5 font-bold">
                    {/* <span style={{ fontSize: '1.4vw' }}>
                        {getRandomInt(5, 17)}
                        <span
                            style={{ fontSize: '1vw' }}
                            className="font-normal"
                        >
                            {' '}
                            Min
                        </span>
                    </span> */}
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ marginTop: "2.5vh" }}>
              <div style={{ fontSize: "1vw" }} className="mt-4 flex relative">
                <p style={{ fontSize: '1.25vw' }} className="absolute">
                  <strong>Uber Black</strong>
                </p>
                <ul
                  style={{ fontSize: "1vw" }}
                  className="ml-auto text-right flex flex-col w-full"
                >
                  <li
                    style={{ fontSize: "0.8vw" }}
                    className="border-b w-full pb-2 mb-0.5 opacity-50"
                  >
                    1.5x rate
                  </li>
                  <li className="border-b border-light-gray w-full pb-0.5 mb-0.5 font-bold">
                    {/* <span style={{ fontSize: '1.4vw' }}>
                        {getRandomInt(4, 22)}
                        <span
                            style={{ fontSize: '1vw' }}
                            className="font-normal"
                        >
                            {' '}
                            Min
                        </span>
                    </span> */}
                  </li>
                  <li className="w-full pb-0.5 mb-0.5 font-bold">
                    {/* <span style={{ fontSize: '1.4vw' }}>
                        {getRandomInt(12, 25)}
                        <span
                            style={{ fontSize: '1vw' }}
                            className="font-normal"
                        >
                            {' '}
                            Min
                        </span>
                    </span> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
    }
}

export default Uber;