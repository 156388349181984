import React, { Component } from 'react'
import { iso_to_seconds } from '../Utils'
import MBTALogo from '../assets/images/mbta-logo.png'
import BusImage from '../assets/images/MBTA-Bus-2.jpg'

const URL = 'https://api-v3.mbta.com/predictions'
const API_KEY = '7b9199fcfb024d1e86a0bee07183de49'
const STATION_STOP_1_IN = '1040' // (64 - Market St @ Guest St - Inbound
const STATION_STOP_1_OUT = '1079' // (64 - Market St @ Guest St - Outbound
const STATION_STOP_2_IN = '1043' // (70 - Arsenal St @ Arlington St - Inbound
const STATION_STOP_2_OUT = '1077' // (70 - Arsenal St @ Arlington St - Outbound
const STATION_STOP_3_IN = '1079' // (86 - Market St @ Centola St - Inbound
const STATION_STOP_3_OUT = '1043' // (86 - Market St @ Centola St - Outbound

var date = new Date()
var ISO_date = date.toISOString()
var current_time_in_seconds = iso_to_seconds(ISO_date)
// let k = 0

class Buses extends Component {
    state = {
        bus1: undefined,
        bus2: undefined,
        bus3: undefined,
        mbta_is_loaded: false,
    }

    componentDidMount() {
        this.getBuses()
    }

    displayBusTime(arrivalTime, i) {
        // console.log('i', i)
        // console.log('k', k)
        // some buses return arrivalTime as null, we need to reset value i to k to reset ech loop at 0
        // if (k > i) {
        //     console.log('reset')
        //     k = 0
        // }
        let difference = parseInt(
            iso_to_seconds(arrivalTime) - current_time_in_seconds
        )

        let comma = ''
        let displayTime = ''

        // if (k + 1 < 2) {
            comma = ','
        // }
        // if (k + 1 > 2) {
        //     displayTime = <span style={{color:'red'}}>0</span>
        // } else 
        if (difference < 0) {
            displayTime = <span style={{ fontSize: '1.35vw' }}>BRD<span style={{ fontSize: '1vw' }} className="comma font-normal">{comma}</span></span>
        } else if (difference < 90) {
            displayTime = <span style={{ fontSize: '1.35vw' }}>ARR<span style={{ fontSize: '1vw' }} className="comma font-normal">{comma}</span></span>
        } else {
            displayTime = (
                <span style={{ fontSize: '1.4vw' }}>
                    {parseInt(difference / 60)}
                    <span style={{ fontSize: '1vw' }} className="font-normal">
                        {' '}
                        Min
                        <span
                            style={{ fontSize: '1vw' }}
                            className="comma font-normal"
                        >
                            {comma}
                        </span>
                    </span>
                </span>
            )
        }
        // k++
        if (displayTime !== false) {
            return (
                <p
                    className="text-right font-bold"
                    style={{ marginLeft: '1vw' }}
                    key={i}
                >
                    {displayTime}
                </p>
            )
        }
        else {
            return
        }
    }

    determineBusDirections(busData, busRoute, inbound, outbound) {
        busData.map((bus) =>
            bus.relationships.route.data.id === busRoute &&
            bus.attributes &&
            bus.attributes.direction_id === 1                
                ? inbound.push(bus)
                : outbound.push(bus)
        )
    }

    getBuses = async (e) => {
        // API CAllS
        const api_bus1in = await fetch( `${URL}?stop=${STATION_STOP_1_IN}&api_key=${API_KEY}` )
        const api_bus2in = await fetch( `${URL}?stop=${STATION_STOP_2_IN}&api_key=${API_KEY}` )
        const api_bus3in = await fetch( `${URL}?stop=${STATION_STOP_3_IN}&api_key=${API_KEY}` )
        const api_bus1out = await fetch( `${URL}?stop=${STATION_STOP_1_OUT}&api_key=${API_KEY}` )
        const api_bus2out = await fetch( `${URL}?stop=${STATION_STOP_2_OUT}&api_key=${API_KEY}` )
        const api_bus3out = await fetch( `${URL}?stop=${STATION_STOP_3_OUT}&api_key=${API_KEY}` )
        // API RESPONSES
        const bus1In = await api_bus1in.json()
        const bus2In = await api_bus2in.json()
        const bus3In = await api_bus3in.json()
        const bus1Out = await api_bus1out.json()
        const bus2Out = await api_bus2out.json()
        const bus3Out = await api_bus3out.json()
        // SET DATA
        if (bus1In && bus1Out && bus2In && bus2Out && bus3In && bus3Out) {
            // // Bus 1
            let inboundBuses1 = []
            let outboundBuses1 = []
            this.determineBusDirections(bus1In.data, '64', inboundBuses1, outboundBuses1)
            this.determineBusDirections(bus1Out.data, '64', inboundBuses1, outboundBuses1)          
            const bus1 = {inbound: inboundBuses1, outbound: outboundBuses1}
            // Bus 2
            let inboundBuses2 = []
            let outboundBuses2 = []
            this.determineBusDirections(bus2In.data, '70', inboundBuses2, outboundBuses2)
            this.determineBusDirections(bus2Out.data, '70', inboundBuses2, outboundBuses2)          
            const bus2 = {inbound: inboundBuses2, outbound: outboundBuses2}
            // // Bus 3
            let inboundBuses3 = []
            let outboundBuses3 = []
            this.determineBusDirections(bus3In.data, '86', inboundBuses3, outboundBuses3)
            this.determineBusDirections(bus3Out.data, '86', inboundBuses3, outboundBuses3)             
            const bus3 = {inbound: inboundBuses3, outbound: outboundBuses3}
            this.setState({
                bus1: bus1,
                bus2: bus2,
                bus3: bus3,
                mbta_is_loaded: true,
                error: '',
            })
        } else {
            this.setState({
                bus1: undefined,
                bus2: undefined,
                bus3: undefined,
                mbta_is_loaded: false,
                error: 'Please enter a city and state and try again.',
            })
        }
    }

    render() {
        if (!this.state.mbta_is_loaded) {
            return <h1>buses</h1>
        } else {
            return (
                <div className="w-full h-full border-2 border-light-gray rounded-xl block p-4 pt-0">
                    <div className="flex absolute right-0 items-end justify-end p-4 rounded-tr-lg header-gradient">
                        <div className="w-3/5">
                            <img
                                alt="MBTA Logo"
                                style={{ maxHeight: '6.5vh' }}
                                src={MBTALogo}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-2/5">
                            <img alt="MBTA bus" src={BusImage} />
                        </div>
                    </div>
                    <h2
                        style={{ fontSize: '1.375vw' }}
                        className="font-bold leading-none"
                    >
                        Bus Schedule
                    </h2>
                    <div className="mb-4 mt-0.5 bg-black w-full h-0.5"></div>
                    {/* Bus 1 */}
                    <div className="mb-4">
                        <div className="flex items-center py-2 -ml-2">
                            <h4
                                className="font-bold"
                                style={{
                                    fontSize: '1.5vw',
                                    lineHeight: '1',
                                    width: '3.5vw',
                                }}
                            >
                                <span
                                    className="rounded-full block pb-px pt-1 text-center m-auto"
                                    style={{
                                        width: '2.75vw',
                                        background: 'orange',
                                    }}
                                >
                                    64
                                </span>
                            </h4>
                            <div>
                                <p
                                    className="font-bold m-0"
                                    style={{
                                        fontSize: '1.25vw',
                                        lineHeight: '0.75',
                                    }}
                                >
                                    Market St @ Guest St
                                </p>
                                <p
                                    className="font-bold"
                                    style={{
                                        fontSize: '1vw',
                                        letterSpacing: '0.125vw',
                                        color: 'orange',
                                    }}
                                >
                                    {/* BUS STOP */}
                                </p>
                            </div>
                        </div>
                        {/* INBOUND */}
                        {this.state.bus1 && (
                            <div
                                style={{
                                    paddingLeft: '1vw',
                                    marginLeft: '1vw',
                                    borderLeft: '0.25vw solid orange',
                                }}
                                className="flex py-1"
                            >
                                <p className="font-bold">Inbound</p>
                                <div className="display-times flex ml-auto">
                                    {this.state.bus1 &&
                                        this.state.bus1.inbound.map(
                                            (bus, i) =>
                                                bus.attributes.direction_id ===
                                                    1 &&
                                                bus.attributes.arrival_time !==
                                                    null &&
                                                this.displayBusTime(
                                                    bus.attributes.arrival_time,
                                                    i
                                                )
                                        )}
                                </div>
                            </div>
                        )}
                        {/* OUTBOUND */}
                        {this.state.bus1 && (
                            <div
                                style={{
                                    paddingLeft: '1vw',
                                    marginLeft: '1vw',
                                    borderLeft: '0.25vw solid orange',
                                }}
                                className="flex py-1"
                            >
                                <p className="font-bold">Outbound</p>
                                <div className="display-times flex ml-auto">
                                    {this.state.bus1 &&
                                        this.state.bus1.outbound.map(
                                            (bus, i) =>
                                                bus.attributes.direction_id ===
                                                    0 &&
                                                bus.attributes.arrival_time !==
                                                    null &&
                                                this.displayBusTime(
                                                    bus.attributes.arrival_time,
                                                    i
                                                )
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Bus 2 */}
                    <div className="mb-4">
                        <div className="flex items-center py-2 -ml-2">
                            <h4
                                className="font-bold"
                                style={{
                                    fontSize: '1.5vw',
                                    lineHeight: '1',
                                    width: '3.5vw',
                                }}
                            >
                                <span
                                    className="rounded-full block pb-px pt-1 text-center m-auto"
                                    style={{
                                        width: '2.75vw',
                                        background: 'orange',
                                    }}
                                >
                                    70
                                </span>
                            </h4>
                            <div>
                                <p
                                    className="font-bold m-0"
                                    style={{
                                        fontSize: '1.25vw',
                                        lineHeight: '0.75',
                                    }}
                                >
                                    Western Ave @ Mackin St
                                </p>
                                <p
                                    className="font-bold"
                                    style={{
                                        fontSize: '1vw',
                                        letterSpacing: '0.125vw',
                                        color: 'orange',
                                    }}
                                >
                                    {/* BUS STOP */}
                                </p>
                            </div>
                        </div>
                        {/* INBOUND */}
                        {this.state.bus2 && (
                            <div
                                style={{
                                    paddingLeft: '1vw',
                                    marginLeft: '1vw',
                                    borderLeft: '0.25vw solid orange',
                                }}
                                className="flex py-1"
                            >
                                <p className="font-bold">Inbound</p>
                                <div className="display-times flex ml-auto">
                                    {this.state.bus2.inbound &&
                                        this.state.bus2.inbound.map(
                                            (bus, i) =>
                                                bus.attributes.direction_id ===
                                                    1 &&
                                                bus.attributes.arrival_time !==
                                                    null &&
                                                this.displayBusTime(
                                                    bus.attributes.arrival_time,
                                                    i
                                                )
                                        )}
                                </div>
                            </div>
                        )}
                        {/* OUTBOUND */}
                        {this.state.bus2 && (
                            <div
                                style={{
                                    paddingLeft: '1vw',
                                    marginLeft: '1vw',
                                    borderLeft: '0.25vw solid orange',
                                }}
                                className="flex py-1"
                            >
                                <p className="font-bold">Outbound</p>
                                <div className="display-times flex ml-auto">
                                    {this.state.bus2.outbound &&
                                        this.state.bus2.outbound.map(
                                            (bus, i) =>
                                                bus.attributes.direction_id ===
                                                    0 &&
                                                bus.attributes.arrival_time !==
                                                    null &&
                                                this.displayBusTime(
                                                    bus.attributes.arrival_time,
                                                    i
                                                )
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Bus 3 */}
                    <div className="mb-4">
                        <div className="flex items-center py-2 -ml-2">
                            <h4
                                className="font-bold"
                                style={{
                                    fontSize: '1.5vw',
                                    lineHeight: '1',
                                    width: '3.5vw',
                                }}
                            >
                                <span
                                    className="rounded-full block pb-px pt-1 text-center m-auto"
                                    style={{
                                        width: '2.75vw',
                                        background: 'orange',
                                    }}
                                >
                                    86
                                </span>
                            </h4>
                            <div>
                                <p
                                    className="font-bold m-0"
                                    style={{
                                        fontSize: '1.25vw',
                                        lineHeight: '0.75',
                                    }}
                                >
                                    Market St @ Centola St
                                </p>
                                <p
                                    className="font-bold"
                                    style={{
                                        fontSize: '1vw',
                                        letterSpacing: '0.125vw',
                                        color: 'orange',
                                    }}
                                >
                                    {/* BUS STOP */}
                                </p>
                            </div>
                        </div>

                        {/* INBOUND */}
                        {this.state.bus3 && (
                            <div
                                style={{
                                    paddingLeft: '1vw',
                                    marginLeft: '1vw',
                                    borderLeft: '0.25vw solid orange',
                                }}
                                className="flex py-1"
                            >
                                <p className="font-bold">Inbound</p>
                                <div className="display-times flex ml-auto">
                                    {this.state.bus3.inbound &&
                                        this.state.bus3.inbound.map(
                                            (bus, i) =>
                                                bus.attributes.direction_id ===
                                                    1 &&
                                                bus.attributes.arrival_time !==
                                                    null &&
                                                this.displayBusTime(
                                                    bus.attributes.arrival_time,
                                                    i
                                                )
                                        )}
                                </div>
                            </div>
                        )}
                        {/* OUTBOUND */}
                        {this.state.bus3 && (
                            <div
                                style={{
                                    paddingLeft: '1vw',
                                    marginLeft: '1vw',
                                    borderLeft: '0.25vw solid orange',
                                }}
                                className="flex py-1"
                            >
                                <p className="font-bold">Outbound</p>
                                <div className="display-times flex ml-auto">
                                    {/* <p className="text-right font-bold" style={{marginLeft: '1vw'}}><span style={{fontSize: '1.4vw'}}>21<span style={{fontSize: '1vw'}} className="font-normal"> Min,</span></span></p>
                                    <p className="text-right font-bold" style={{marginLeft: '1vw'}}><span style={{fontSize: '1.4vw'}}>21<span style={{fontSize: '1vw'}} className="font-normal"> Min</span></span></p> */}
                                    {this.state.bus3.outbound &&
                                        this.state.bus3.outbound.map(
                                            (bus, i) =>
                                                bus.attributes.direction_id ===
                                                    0 &&
                                                bus.attributes.arrival_time !==
                                                    null &&
                                                this.displayBusTime(
                                                    bus.attributes.arrival_time,
                                                    i
                                                )
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }
    }
}

export default Buses
