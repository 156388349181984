import React, { Component } from 'react';

class BlueBikes extends Component {

    state = {
        bike: undefined,
    }

    componentDidMount() {
        this.getWeather();
    }

    getWeather = async e => {
        const location = {
            lat: 42.1260454,
            long: -71.4620601
        }
        console.log(location)
        // API Call
        const api_call = await fetch(
          `https://gbfs.bluebikes.com/gbfs/en/station_status.json`
        );
        // Awaiting the Response...
        const data = await api_call.json()
        // Set state after API response
        let bikeArray = []
        if (data) {
            data.data.stations.map((station) =>
              station.station_id === "428" || station.station_id === "10"
                ? bikeArray.push(station)
                : ""
            );
            bikeArray.forEach(bike => {
                if (bike.station_id === "428") {
                    bike.location = "Western Ave at Richardson St"
                }
                if (bike.station_id === "10") {
                  bike.location = "New Balance - 20 Guest St";
                }
            })
            this.setState({
                bikes: bikeArray,
            })
        } else {
            this.setState({
                bikes: undefined
            })
        }
        console.log("bikes");
    }

    render() {
        if (!this.state.bikes) {
            return (
                <h1>Bicycles</h1>
            )
        } else {
            return (
              <div className="component Events">
                {console.log(this.state.bikes)}
                <h1>Nearby Blue Bikes</h1>
                {this.state.bikes.map((bike, index) => (
                  <div className="bike__block" key={index}>
                    <p className="bike__location">
                      <strong>{bike.location}</strong>
                    </p>
                    <div className="bike__info">
                      <div className="bike__stock">
                        <p className="bike__number">
                          {bike.num_bikes_available}
                        </p>
                        <p className="bike__description">Bikes</p>
                      </div>
                      <div className="bike__stock">
                        <p className="bike__number">
                          {bike.num_docks_available}
                        </p>
                        <p className="bike__description">Docks</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
        }
    }
}

export default BlueBikes;
