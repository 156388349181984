import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { iso_to_date } from '../Utils';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

class Events extends Component {

    state = {
        events: undefined,
    }

    componentDidMount() {
        this.getEvents();
    }

    getEvents = async e => {
        // API keys
        const API_KEY = 'MjEyMDk3NTd8MTU5MTY2Mjk3MS4z';
        const city = "Boston"
        const start_date = `${this.props.year}-${this.props.month}-${this.props.day}`
        const end_date = `${this.props.weekAhead}`
        // API Call
        const api_call = await fetch(
            `https://api.seatgeek.com/2/events?venue.city=${city}&datetime_utc.gte=${start_date}&datetime_utc.lte=${end_date}&client_id=${API_KEY}`
        );
        // Awaiting the Response...
        const data = await api_call.json()
        // Set state after API response
        if (data && data.length !== 0) {
            let popularEvents = [];
            data.events.forEach(element => {
                if (
                  (element.performers[0] &&
                    element.performers[0].score > 0.35 &&
                    element.score > 0.2) ||
                    element.score > 0.35
                ) {
                  popularEvents.push(element);
                  element.date = iso_to_date(
                    element.datetime_local,
                    this.props.months
                  );
                }
            });
            this.setState({
                events: popularEvents
            })
        } else {
            this.setState({
                events: undefined
            })
        }
    }

    render() {
        if (!this.state.events) {
            return (
              <h2 style={{ fontSize: "1.25vw" }} className="leading-none">
                Events
              </h2>
            );
        } else {
            return (
              <div className="relative h-full">
                <h2 style={{ fontSize: "1.25vw" }} className="leading-none">
                  Events
                </h2>
                <div className="mt-0.5 bg-black w-full h-0.5"></div>
                <Splide
                  options={{
                    type: "loop",
                    autoplay: true,
                    rewind: true,
                    cover: true,
                    speed: 2000,
                    interval: 10000,
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    arrows: false,
                  }}
                  className="w-full h-full"
                >
                  {this.state.events.map((event) => (
                    <SplideSlide className="w-full h-full" key={event.url}>
                      <p
                        style={{ fontSize: "1vw" }}
                        className="mt-2 leading-snug uppercase"
                      >
                        <strong>{event.title}</strong>
                      </p>
                      <p
                        style={{ fontSize: "1vw" }}
                        className="mb-2 leading-snug flex opacity-50 items-end"
                      >
                        {event.venue.name} - {event.venue.city}
                      </p>
                      <div className="mt-2 flex">
                        <div className="w-2/5 mr-4 flex flex-col">
                          <div
                            style={{
                              backgroundImage: `url(${event.performers[0].image})`,
                              height: "11vh",
                            }}
                            className="w-full bg-cover bg-center"
                          ></div>
                          <p
                            style={{ fontSize: "1vw" }}
                            className="font-bold text-center uppercase"
                          >
                            {event.date[0].split(",")[0]}
                          </p>
                          <p
                            style={{ fontSize: "3vw" }}
                            className="text-center font-light leading-none"
                          >
                            {event.date[0].split(" ")[1]}
                          </p>
                        </div>
                        <div className="w-3/5">
                          <p
                            style={{ fontSize: "1vw" }}
                            className="leading-snug"
                          >
                            <strong>Performing: </strong>
                            {event.performers.map((performer, count) => {
                              if (count === event.performers.length - 1) {
                                count++;
                                return performer.name;
                              } else {
                                count++;
                                return performer.name + ", ";
                              }
                            })}
                          </p>
                          <p
                            style={{ fontSize: "1vw" }}
                            className="leading-snug"
                          >
                            <strong>Time: </strong>
                            {event.date[1]}
                          </p>
                          <br />
                          <p
                            style={{ fontSize: "1vw" }}
                            className="leading-snug"
                          >
                            <strong>{event.venue.name}</strong>
                            <br />
                            <span className="opacity-50">
                              {event.venue.address} {event.venue.addres}{" "}
                              {event.venue.display_location}
                            </span>
                          </p>
                        </div>
                      </div>
                    </SplideSlide>
                  ))}
                </Splide>
              </div>
            );
        }
    }
}

export default Events;
